import styles from "./Navigation.module.scss";

/* The Following need actionid for Navigation, SubFooter, SubNavigation and MobileNav:
Jobs, Sustainability, Sales Enquirey, Purpose, Jobs, Innovation, Complaints Charter, Packaging Tips, Warehouse, You & DPD, Our Jobs */

const Navigation = ({ businessTheme: { domain, local } }) => {
  return (
    <div className={`dpd-row ${styles.mainNavigation} hide-for-small`}>
      <div className="dpd-row">
        <div
          className={`large-12 ${styles.mainNavigation} dpd-columns navigation`}
        >
          <ul className={`${styles.mainNavUL}  ${local ? styles.local : ""}`}>
            <li className={styles.mainNavLi}>
              <a
                title="Home"
                className={styles.mainNavAnchor}
                actionid="tY6Fre6C48kIGI8q"
                href={`${domain}/index.jsp`}
              >
                Home
              </a>
            </li>
            <li className={styles.mainNavLi}>
              <a
                title="Products &amp; Services"
                className={styles.mainNavAnchor}
                actionid="Avylre6C48kIGOWD"
                href={`${domain}/content/products_services/index.jsp`}
              >
                Products &amp; Services
              </a>
            </li>
            <li className={styles.mainNavLi}>
              <a
                className={`${styles.active} ${styles.mainNavAnchor} ${
                  local ? styles.activeLocal : ""
                }`}
                title="Help"
                actionid="0H_lre6C48kIGOX0"
                href={`${domain}/content/how-can-we-help/index.jsp`}
              >
                Help
              </a>
            </li>
            <li className={styles.mainNavLi}>
              <a
                title="MyDPD"
                className={styles.mainNavAnchor}
                actionid="4301re6C48kIGOZP"
                href={`${domain}/content/my_dpd/index.jsp`}
              >
                {!local ? "MyDPD" : "MyDPD Local"}
              </a>
            </li>
            {local && (
              <li className={styles.mainNavLi}>
                <a
                  title="About DPD local"
                  actionid="nyqTre6C48kIGOnf"
                  className={styles.mainNavAnchor}
                  href={`${domain}/content/about_dpd/index.jsp`}
                >
                  About DPD Local
                </a>
              </li>
            )}
            {local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Sustainability"
                  className={styles.mainNavAnchor}
                  href={process.env.REACT_APP_GREEN}
                >
                  Sustainability
                </a>
              </li>
            )}
            {local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Innovation"
                  className={styles.mainNavAnchor}
                  href={process.env.REACT_APP_INNOVATION}
                >
                  Innovation
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Jobs"
                  className={styles.mainNavAnchor}
                  href={`https://jobs.dpd.co.uk/`}
                >
                  Jobs
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Innovation"
                  className={styles.mainNavAnchor}
                  href={process.env.REACT_APP_INNOVATION}
                >
                  Innovation
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Sustainability"
                  className={styles.mainNavAnchor}
                  href={process.env.REACT_APP_GREEN}
                >
                  Sustainability
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Purpose"
                  className={styles.mainNavAnchor}
                  href={`${domain}/content/about_dpd/purpose.jsp`}
                >
                  Purpose
                </a>
              </li>
            )}
            <li className={styles.mainNavLi}>
              <a
                title="Sales Enquiry"
                className={styles.mainNavAnchor}
                href={`${domain}/content/my_dpd/sales-enquiry.jsp`}
              >
                Sales Enquiry
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
