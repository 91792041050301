export const COUNTRY_CODES = {
  AUSTRIA: "AT",
  BELGIUM: "BE",
  CZECH_REPUBLIC: "CZ", // Use uppercase and underscores for consistency
  DENMARK: "DK",
  ESTONIA: "EE",
  FINLAND: "FI",
  FRANCE: "FR",
  GERMANY: "DE",
  LATVIA: "LV",
  LITHUANIA: "LT",
  LUXEMBOURG: "LU",
  NETHERLANDS: "NL",
  POLAND: "PL",
  PORTUGAL: "PT",
  SLOVAKIA: "SK",
  SPAIN: "ES",
  SWEDEN: "SE",
  SWITZERLAND: "CH",
  UNITED_KINGDOM: "GB", // Use uppercase and underscores for consistency
};
