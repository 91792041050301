import { useState } from "react";
import ModalItem from "../Modal/Modal";

import styles from "./ListItem.module.scss";
import {
  WEEK_DAYS,
  getDayAbbreviation,
  mapOpeningHours,
} from "../../utils/date";

const ListItem = ({
  location,
  index,
  home,
  domain,
  distance,
  postcode,
  translation,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { locality, town } = location.address;

  const handleClick = (e) => {
    // eslint-disable-next-line
    if (e.target.type === "submit") {
      return setShowModal(true);
    }
  };

  const renderTown = (town, locality) => {
    if (locality) {
      return (
        <>
          <br />
          {locality}
          <br />
        </>
      );
    } else if (town) {
      return (
        <>
          <br />
          {town}
          <br />
        </>
      );
    }
    return (
      <>
        <br />
      </>
    );
  };

  const openingHours = mapOpeningHours(
    location.pickupLocationAvailability.pickupLocationOpenWindow
  );

  const checkAndDisplayTimes = (schedule) => {
    // Filter weekdays and weekend days separately (considering missing days)
    const allDays = WEEK_DAYS.map((day) => {
      const entry = schedule.find((entry) => entry.day === day);
      return entry || { day, time: null };
    });
    const weekdays = allDays.slice(0, 5);
    const weekend = allDays.slice(5);

    // Check if all weekdays have the same opening and closing times
    const isWeekdaysSameTime = weekdays.every((day) => {
      const openingTimes = day.time?.split(", ");
      return weekdays.every((otherDay) =>
        otherDay.time
          ?.split(", ")
          .every((otherTime) => openingTimes?.includes(otherTime))
      );
    });

    // Prepare weekday time display with 3-letter day names
    const weekdayDisplay = isWeekdaysSameTime ? (
      <tr>
        <td key="weekdays">
          {translation[getDayAbbreviation(weekdays[0].day)]}-
          {translation[getDayAbbreviation(weekdays[weekdays.length - 1].day)]}:
        </td>
        <td key="weekdays-time">{weekdays[0].time}</td>
      </tr>
    ) : (
      weekdays.map((day, index) => (
        <tr key={index}>
          <td>{translation[getDayAbbreviation(day.day)]}:</td>
          <td>{day.time || translation["Closed"]}</td>
        </tr>
      ))
    );

    // Prepare weekend time display with "Closed" for missing days
    const weekendDisplay = weekend.map((day, index) => (
      <tr key={index}>
        <td>{translation[getDayAbbreviation(day.day)]}:</td>
        <td>{day.time || translation["Closed"]}</td>
      </tr>
    ));

    return (
      <>
        {weekdayDisplay}
        {weekendDisplay}
      </>
    );
  };

  return (
    <div
      className={`${styles.container} ${
        (index + 1) % 2 === 0 ? styles.even : null
      } active`}
      onClick={(e) => handleClick(e)}
      index={index}
    >
      <div className={styles.summary}>
        <div className={styles.row}>
          <div className={styles.marker}>
            <img
              src={`/assets/markers/dpd_pickup_map-pin_${index + 1}.fw.png`}
              alt="marker"
            />
          </div>
          <div className={styles.address}>
            <strong>{location.address.organisation}</strong>
            {location.address.street}
            {renderTown(town, locality)}
            {location.address.postcode}
          </div>
          <div className={styles.distance}>
            <img src="/assets/car.png" alt="car" />
            <br />
            <span>{distance.toString().substring(0, 3)} Miles</span>
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.locationIcons}>
          {location.dropOffDigital &&
            location.dropOffReturn &&
            location.dropOffDpd && (
              <img
                className={styles.icons}
                src={`assets/barcode-label-printing.svg`}
                alt=""
              />
            )}
          {location.disabledAccess && (
            <img
              className={styles.icons}
              src={`assets/wheelchair.png`}
              alt=""
            />
          )}
          {location["openLate"] && (
            <img className={styles.icons} src={`assets/open-late.png`} alt="" />
          )}
          {location["openSaturday"] && (
            <img
              className={styles.icons}
              src={`assets/open-saturday.png`}
              alt=""
            />
          )}
          {location["openSunday"] && (
            <img
              className={styles.icons}
              src={`assets/open-sunday.png`}
              alt=""
            />
          )}
          {location["parkingAvailable"] && (
            <img className={styles.icons} src={`assets/parking.png`} alt="" />
          )}
        </div>
        <div className={styles.locationOpening}>
          <div className="dpd-columns">
            <p className={styles.heading}>
              {translation["NORMAL_OPENING_TIMES"]}
            </p>
            <table>
              <tbody>{checkAndDisplayTimes(openingHours)}</tbody>
            </table>
          </div>
        </div>
        <div className={`dpd-columns ${styles.buttonCon}`}>
          <button className={styles.button}>{translation.BUTTON_SELECT}</button>
        </div>
      </div>
      {showModal && (
        <ModalItem
          setShowModal={setShowModal}
          location={location}
          home={home}
          index={index}
          domain={domain}
          distance={distance}
          postcode={postcode}
          translation={translation}
        />
      )}
    </div>
  );
};

export default ListItem;
