import styles from "./Filter.module.scss";
import ListItem from "../ListItem/ListItem";

import React, { useEffect, useRef } from "react";

const Filter = ({
  expandFilter,
  setExpandFilter,
  translation,
  pickupData,
  loading,
  setPickupData,
  pickupDataRef,
  domain,
  postcode,
  urlParams,
  updateParams,
}) => {
  const filtersList = useRef(null);

  useEffect(() => {
    if (expandFilter) {
      filtersList.current.style.display = "inherit";
    }
    if (!expandFilter) {
      filtersList.current.style.display = "none";
    }
  }, [expandFilter]);

  const renderPickupItem = () => {
    return pickupData.results.map((location, index) => {
      return (
        <ListItem
          key={index}
          home={location.pickupLocation.addressPoint}
          location={location.pickupLocation}
          index={index}
          domain={domain}
          distance={location.distance}
          postcode={postcode}
          translation={translation}
        />
      );
    });
  };

  return (
    <div className={styles.filterContainer}>
      <div
        className={styles.pickupFilter}
        onClick={() => {
          setExpandFilter(!expandFilter);
        }}
      >
        <span className={styles.filterTitle}>{translation.FILTER_LIST}</span>
        <span className={styles.icons}>
          {!expandFilter ? (
            <i className={styles.plus}>+</i>
          ) : (
            <i className={styles.minus}>-</i>
          )}
        </span>
      </div>

      <div
        className={`${styles.filterContent} ${styles.filterLists}`}
        ref={filtersList}
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="dpd-row dpd-columns">
            <button
              className={styles.showAll}
              onClick={() => {
                if (pickupDataRef.current) {
                  const checked = [
                    ...document.querySelectorAll(
                      "input[type=checkbox]:checked"
                    ),
                  ];
                  checked.forEach((box) => {
                    box.checked = false;
                  });
                  setPickupData(pickupDataRef.current);
                  setExpandFilter(!expandFilter);
                }
                updateParams();
              }}
            >
              {translation.BUTTON_SHOWALL}
            </button>
          </div>
          <div className="dpd-row">
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id="barcodeLabelPrinting"
                defaultChecked={urlParams.has("barcodeLabelPrinting")}
              />
              <label
                htmlFor="barcodeLabelPrinting"
                className={styles.filterLabel}
              >
                {translation["Barcode Label Printing"]}
              </label>
            </div>
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id="openLate"
                className={styles.filterCheckbox}
                defaultChecked={urlParams.has("openLate")}
              />
              <label htmlFor="openLate" className={styles.filterLabel}>
                {translation["Open Late"]}
              </label>
            </div>
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id="parkingAvailable"
                className={styles.filterCheckbox}
                defaultChecked={urlParams.has("parkingAvailable")}
              />
              <label htmlFor="parkingAvailable" className={styles.filterLabel}>
                {translation["Car Parking"]}
              </label>
            </div>
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id="openSaturday"
                defaultChecked={urlParams.has("openSaturday")}
              />
              <label htmlFor="openSaturday" className={styles.filterLabel}>
                {translation["Open Saturdays"]}
              </label>
            </div>
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id="openSunday"
                defaultChecked={urlParams.has("openSunday")}
              />
              <label htmlFor="openSunday" className={styles.filterLabel}>
                {translation["Open Sundays"]}
              </label>
            </div>
            <div className={styles.filterItem}>
              <input
                type="checkbox"
                id="disabledAccess"
                defaultChecked={urlParams.has("disabledAccess")}
              />
              <label htmlFor="disabledAccess" className={styles.filterLabel}>
                {translation["Good Wheelchair Access"]}
              </label>
            </div>
            <button
              className={`${styles.showAll} ${styles.filter}`}
              onClick={() => updateParams()}
            >
              Filter
            </button>
          </div>
        </form>
      </div>

      {Object.keys(pickupData).length !== 0 ? renderPickupItem() : null}
    </div>
  );
};

export default Filter;
