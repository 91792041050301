import React, { useState, useRef, useEffect } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Filter from "../Filter/Filter";
import Map from "../Mapbox/Map";
import MobileToggle from "../MobileToggle/MobileToggle";
import axios from "axios";
import { UK_POSTCODE_REGEX } from "../../utils/regex";
import { getPosition } from "../../utils/helpers";

import styles from "./Pickup.module.scss";
import { COUNTRY_CODES } from "../../constants/countries";
import { languages } from "../../utils/languages";

const Pickup = ({
  businessTheme: { domain, local, businessUnit },
  mobileNavShowing,
  dropoff,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramPostcode = urlParams.get("postcode");
  const paramCountry = urlParams.get("country");
  const paramLanguage = urlParams.get("language");
  const lang = paramLanguage
    ? languages.find((country) => country.text === paramLanguage)
    : null;

  const [language, setLanguage] = useState({
    flag: lang ? lang.flag : "GB",
    text: paramLanguage || "English",
  });
  const [country, setCountry] = useState(paramCountry || "United Kingdom");
  const [countryFlag, setCountryFlag] = useState(
    paramCountry
      ? COUNTRY_CODES[paramCountry.toUpperCase().replace(/\s/g, "_")]
      : "GB"
  );
  const [postcode, setPostcode] = useState(paramPostcode || "");
  const [expandFilter, setExpandFilter] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Please enter a valid postcode");
  const [translation, setTranslation] = useState(
    lang
      ? require(`../../translations/locale-${lang.flag.toLowerCase()}.json`)
      : require("../../translations/locale-gb.json")
  );
  const [loading, setLoading] = useState(false);
  const [pickupData, setPickupData] = useState({});
  const map = useRef(null);
  const pickupDataRef = useRef(null);

  const getPickUpData = async (url) => {
    try {
      setLoading(true);
      setShowError(false);
      const { data } = await axios.get(url);
      if (data.totalResults === 0) {
        setErrorMsg("No results found");
        setShowError(true);
      }

      const checked = [
        ...document.querySelectorAll("input[type=checkbox]:checked"),
      ];

      const filteredResults = data.results.filter((item) =>
        checked.every((val) => {
          if (val.id === "barcodeLabelPrinting") {
            return (
              item.pickupLocation["dropOffDigital"] &&
              item.pickupLocation["dropOffReturn"] &&
              item.pickupLocation["dropOffDpd"]
            );
          } else {
          }
          return item.pickupLocation[val.id];
        })
      );

      const filteredData = {
        ...pickupData,
        results: filteredResults,
      };
      setPickupData(filteredData);
      pickupDataRef.current = filteredData;
      setLoading(false);
    } catch (error) {
      setShowError(true);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const appEngineURL = local
      ? process.env.REACT_APP_API_URL_LOCAL
      : process.env.REACT_APP_API_URL;

    if (postcode.length > 0) {
      if (country === "United Kingdom") {
        if (!UK_POSTCODE_REGEX.test(postcode)) {
          setErrorMsg("Please enter a valid postcode");
          return setShowError(true);
        } else {
          const url = `${appEngineURL}/pickuplocation?address=${postcode}&searchPageSize=50&businessUnit=${businessUnit}&dropoff=true`;
          return await getPickUpData(url);
        }
      }

      const url = `${appEngineURL}/pickuplocation?address=${postcode}&countryCode=${countryFlag}&searchPageSize=50&businessUnit=${businessUnit}&maxDistance=50&dropoff=true`;
      return await getPickUpData(url);
    } else {
      try {
        const { coords } = await getPosition();
        const url = `${appEngineURL}/pickuplocation?countryCode=${countryFlag}&latitude=${coords.latitude}&longitude=${coords.longitude}&searchPageSize=50&businessUnit=${businessUnit}&maxDistance=50&dropoff=true`;
        return await getPickUpData(url);
      } catch (error) {
        if (urlParams.has("postcode")) {
          setErrorMsg("Please enable location services and try again");
          setShowError(true);
        }
      }
    }
  };

  const updateParams = () => {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split("?")[0];

    const checked = [
      ...document.querySelectorAll("input[type=checkbox]:checked"),
    ];
    const checkedValues = checked.map((checkedBox) => checkedBox.id);

    let newUrl = new URL(
      `${baseUrl}?postcode=${postcode}&country=${country}&language=${language.text}`
    );

    if (checkedValues.length > 0) {
      newUrl = newUrl + "&" + checkedValues.join("&");
    }

    window.location.href = newUrl;
  };

  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`dpd-columns ${styles.pickupContainer} ${
        mobileNavShowing ? styles.moveLeft : null
      }`}
    >
      <Breadcrumb local={local} domain={domain} />
      <div className="dpd-columns">
        <div className={styles.textHeading}>
          <h4>DPD Parcel Shop Finder</h4>
        </div>
        <div className={styles.textHeading}>
          <h6>
            Pickup, Drop off, or return your parcels from a DPD Parcel Shop
            close to you
          </h6>
        </div>
        <div className={`dpd-columns ${styles.appContainer}`}>
          {showError && (
            <div className={styles.errorMsg}>
              <div className={styles.alert}>
                <button
                  type="button"
                  className={styles.close}
                  onClick={() => setShowError(false)}
                >
                  <span>×</span>
                </button>
                <span className={styles.inform}>
                  <span>{errorMsg}</span>
                </span>
              </div>
            </div>
          )}
          <div className="dpd-row">
            <LanguageSelector
              setLanguage={setLanguage}
              language={language}
              translation={translation}
              setTranslation={setTranslation}
            />
            <div className={styles.rowForm}>
              <div className="dpd-row">
                <form
                  className={styles.panel}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="dpd-row">
                    <div className={styles.countryRow}>
                      <label className={styles.label}>
                        {translation.Country}
                      </label>
                      <select
                        className={`${styles.selectorContainer} ${styles.countrySelect}`}
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setCountryFlag(
                            e.currentTarget[
                              e.target.selectedIndex
                            ].getAttribute("flag")
                          );
                        }}
                      >
                        <option value="Austria" flag="AT">
                          {translation.Austria}
                        </option>
                        <option value="Belgium" flag="BE">
                          {translation.Belgium}
                        </option>
                        <option value="Czech Republic" flag="CZ">
                          {translation["Czech Republic"]}
                        </option>
                        <option value="Denmark" flag="DK">
                          {translation.Denmark}
                        </option>
                        <option value="Estonia" flag="EE">
                          {translation.Estonia}
                        </option>
                        <option value="Finland" flag="FI">
                          {translation.Finland}
                        </option>
                        <option value="France" flag="FR">
                          {translation.France}
                        </option>
                        <option value="Germany" flag="DE">
                          {translation.Germany}
                        </option>
                        <option value="Latvia" flag="LV">
                          {translation.Latvia}
                        </option>
                        <option value="Lithuania" flag="LT">
                          {translation.Lithuania}
                        </option>
                        <option value="Luxembourg" flag="LU">
                          {translation.Luxembourg}
                        </option>
                        <option value="Netherlands" flag="NL">
                          {translation.Netherlands}
                        </option>
                        <option value="Poland" flag="PL">
                          {translation.Poland}
                        </option>
                        <option value="Portugal" flag="PT">
                          {translation.Portugal}
                        </option>
                        <option value="Slovakia" flag="SK">
                          {translation.Slovakia}
                        </option>
                        <option value="Spain" flag="ES">
                          {translation.Spain}
                        </option>
                        <option value="Sweden" flag="SE">
                          {translation.Sweden}
                        </option>
                        <option value="Switzerland" flag="CH">
                          {translation.Switzerland}
                        </option>
                        <option value="United Kingdom" flag="GB">
                          {translation["United Kingdom"]}
                        </option>
                      </select>
                    </div>
                    <div className={styles.postcodeRow}>
                      <div className={`dpd-row ${styles.postcodeContainer}`}>
                        <div className={styles.postcodeInput}>
                          <label className={styles.label}>
                            {translation.POSTCODE_PLACEHOLDER}
                          </label>
                          <input
                            type="text"
                            placeholder={translation.POSTCODE_PLACEHOLDER}
                            value={postcode}
                            className={styles.input}
                            onChange={(e) => setPostcode(e.target.value)}
                          />
                        </div>
                        <div className={styles.magniGlass}>
                          <span className={styles.glassImg}>
                            <img
                              alt="Search"
                              src="/assets/magnifying-glass-gray.png"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.searchButton}>
                      <button
                        className={local ? styles.local : null}
                        type="submit"
                        onClick={() => updateParams()}
                      >
                        {translation.SEARCH_BUTTON_TEXT}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={styles.mapRow}>
            <MobileToggle
              setShowFilter={setShowFilter}
              setShowMap={setShowMap}
              showFilter={showFilter}
              showMap={showMap}
              translation={translation}
            />
            {showMap && (
              <div className={styles.mapContainer}>
                <Map map={map} pickupData={pickupData} />
              </div>
            )}
            {showFilter && (
              <Filter
                expandFilter={expandFilter}
                setExpandFilter={setExpandFilter}
                translation={translation}
                pickupData={pickupData}
                loading={loading}
                setPickupData={setPickupData}
                pickupDataRef={pickupDataRef}
                domain={domain}
                postcode={postcode}
                urlParams={urlParams}
                updateParams={updateParams}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pickup;
