const isHover = (e) => e.parentElement.querySelector(":hover") === e;

export const checkIfHover = (elementClassname) => {
  const element = document.querySelector(`.${elementClassname}`);

  document.addEventListener("mousemove", function checkHover() {
    const hovered = isHover(element);
    if (hovered !== checkHover.hovered) {
      console.log(hovered ? "hovered" : "not hovered");
      checkHover.hovered = hovered;
    }
  });
};

export const getPosition = (options) => {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};
