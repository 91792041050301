import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import "./styles/globals.scss";
import "./styles/flags.scss";

const businessUnit = window.location.hostname.includes("local") ? 2 : 1;

const favicon = document.getElementsByTagName("link");

if (businessUnit === 2) {
  favicon[0].href = "/favicon-local.ico";
}

const businessTheme = {
  businessUnit: businessUnit,
  domain:
    businessUnit === 1
      ? process.env.REACT_APP_APP_DPD_DOMAIN
      : process.env.REACT_APP_APP_DPD_DOMAIN_LOCAL,
  local: businessUnit === 1 ? false : true,
};

ReactDOM.render(
  <React.StrictMode>
    <App businessTheme={businessTheme} />
  </React.StrictMode>,
  document.getElementById("root")
);
