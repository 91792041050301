import React, { useState, useEffect } from "react";
import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";
import tracker from "./utils/analytics";

import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";
import SubNavigation from "./components/SubNavigation/SubNavigation";
import SubFooter from "./components/SubFooter/SubFooter";
import Footer from "./components/Footer/Footer";
import MobileNav from "./components/MobileNav/MobileNav";
import Pickup from "./components/Pickup/Pickup";

function App({ businessTheme }) {
  const [showMobileNav, setShowMovileNav] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width >= 640) {
      setShowMovileNav(false);
    }
  }, [width, setShowMovileNav]);

  return (
    <TrackerProvider tracker={tracker}>
      <div className="App">
        <Header businessTheme={businessTheme} />
        <div className="nav-container">
          <MobileNav
            setShowNav={setShowMovileNav}
            showNav={showMobileNav}
            businessTheme={businessTheme}
          />
          <Navigation businessTheme={businessTheme} />
        </div>
        <div className="dpd-row flex">
          <SubNavigation businessTheme={businessTheme} />
          <Pickup
            businessTheme={businessTheme}
            mobileNavShowing={showMobileNav}
          />
        </div>
        <SubFooter businessTheme={businessTheme} />
        <Footer businessTheme={businessTheme} />
      </div>
    </TrackerProvider>
  );
}

export default App;
