export const languages = [
  { flag: "NL", text: "Nederlands" },
  { flag: "GB", text: "English" },
  { flag: "FR", text: "Français" },
  { flag: "DE", text: "Deustche" },
  { flag: "ES", text: "Español" },
  { flag: "PT", text: "Português" },
  { flag: "EE", text: "Estonian" },
  { flag: "LV", text: "Latvian" },
  { flag: "LT", text: "Lithuanian" },
  { flag: "AT", text: "Austria" },
  { flag: "DK", text: "Dansk" },
  { flag: "SE", text: "Svenska" },
  { flag: "FI", text: "Suomi" },
  { flag: "CZ", text: "Čeština" },
  { flag: "PL", text: "Polish" },
  { flag: "SK", text: "Slovensky" },
];
