import React, { useState, useEffect } from "react";
import { languages } from "../../utils/languages";
import { getTranslation } from "../../utils/getTranslation";

import styles from "./LanguageSelector.module.scss";

const LanguageSelector = ({
  setLanguage,
  language,
  translation,
  setTranslation,
}) => {
  const [showLanguage, setShowLanguage] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (!e.target.id.includes("langSelector")) {
        setShowLanguage(false);
      }
    });
  }, []);

  return (
    <div className={styles.lanRow}>
      <label htmlFor="language" className={styles.label}>
        {translation.Language}
      </label>
      <div
        id="langSelector"
        className={styles.selectorContainer}
        onClick={() => setShowLanguage(!showLanguage)}
      >
        <span className={`${styles.flagIcon} flag${language.flag}`}></span>
        &nbsp;{language.text}
        <span className={styles.arrowDown}></span>
        {showLanguage && (
          <div
            className={`${styles.langSelector}`}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {languages.map((lang, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.countryContainer} ${
                    lang.text === language.text && isHover === false
                      ? styles.highlight
                      : ""
                  }`}
                  onClick={() => {
                    setLanguage({ flag: lang.flag, text: lang.text });
                    setTranslation(getTranslation(lang.flag));
                    setIsHover(false);
                  }}
                >
                  <span
                    className={`${styles.flagIcon} flag${lang.flag}`}
                  ></span>
                  &nbsp;<div>{lang.text}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
