import styles from "./SubNavigation.module.scss";

const SubNavigation = ({ businessTheme: { domain, local } }) => {
  return (
    <div className={`${styles.subNavigation}`}>
      <ul className={`${styles.subNavUl} ${local ? styles.local : ""}`}>
        <li className={styles.subNavLi}>
          <a
            title="Track My Parcel"
            className={styles.subNavAnchor}
            actionid="oekHre6C48kIGJZU"
            href={`${domain}/content/how-can-we-help/index.jsp`}
          >
            Track My Parcel
          </a>
        </li>
        <li className={styles.subNavLi}>
          <a
            title="DPD Parcel Shop Finder"
            className={`${styles.subNavAnchor} ${
              !local ? styles.active : styles.activeLocal
            }`}
            actionid="GHxPre6C48kIGJgC"
            href="/"
          >
            DPD Parcel Shop Finder
          </a>
        </li>
        <li className={styles.subNavLi}>
          <a
            title="Find My DPD Depot"
            className={`${styles.subNavAnchor}`}
            actionid="N5Lvre6C48kIGJif"
            href={
              local
                ? process.env.REACT_APP_DEPOT_LOCAL
                : process.env.REACT_APP_DEPOT
            }
          >
            {!local ? "Find My DPD Depot" : "Find My Local Depot"}
          </a>
        </li>
        <li className={styles.subNavLi}>
          <a
            title="Service Disruption"
            className={styles.subNavAnchor}
            actionid="mZ3TXe6C48kIGNKx"
            href={`${domain}/content/products_services/uk_issues.jsp`}
          >
            Service Disruption
          </a>
        </li>
        {!local && (
          <li className={styles.subNavLi}>
            <a
              title="Your Delivery Preferences"
              className={styles.subNavAnchor}
              actionid="oRvLXe6C48kIGNOz"
              href={`${domain}/apps/delivery_preferences/dpd-your-delivery-preferences.jsp`}
            >
              Your Delivery Preferences
            </a>
          </li>
        )}
        {!local && (
          <li className={styles.subNavLi}>
            <a
              title="Complaints Charter"
              className={styles.subNavAnchor}
              href={`${domain}/pdf/dpd-complaints-charter.pdf`}
            >
              Complaints Charter
            </a>
          </li>
        )}
        {!local && (
          <li className={styles.subNavLi}>
            <a
              title="Packaging Tips"
              className={styles.subNavAnchor}
              href={`${domain}/content/products_services/packaging-tips.jsp`}
            >
              Packaging Tips
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SubNavigation;
