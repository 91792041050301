export const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const mapOpeningHours = (openingHours) => {
  const openingHoursMapped = openingHours.map(
    ({ pickupLocationOpenWindowDay: day }) => {
      const sameDayData = openingHours.filter(
        ({ pickupLocationOpenWindowDay }) => pickupLocationOpenWindowDay === day
      );

      let times = "";

      if (sameDayData[0]) {
        times = `${sameDayData[0].pickupLocationOpenWindowStartTime} - ${sameDayData[0].pickupLocationOpenWindowEndTime}`;

        if (sameDayData[1]) {
          if (
            sameDayData[0].pickupLocationOpenWindowEndTime ===
            sameDayData[1].pickupLocationOpenWindowStartTime
          ) {
            times = `${sameDayData[0].pickupLocationOpenWindowStartTime} - ${sameDayData[1].pickupLocationOpenWindowEndTime}`;
          } else {
            times =
              times +
              ", " +
              `${sameDayData[1].pickupLocationOpenWindowStartTime} - ${sameDayData[1].pickupLocationOpenWindowEndTime}`;
          }
        }
      }

      return {
        day: WEEK_DAYS[day - 1],
        time: times,
      };
    }
  );

  return openingHoursMapped.reduce((total, current) => {
    if (total && total.findIndex(({ day }) => day === current.day) === -1) {
      return [...total, { ...current }];
    }

    return [...total];
  }, []);
};

export const getDayAbbreviation = (day) => {
  return day.slice(0, 3);
};
