import styles from "./Header.module.scss";

const Header = ({ businessTheme: { domain, local } }) => {
  return (
    <div className={`dpd-row ${styles.header}`}>
      <div className={`dpd-columns ${styles.navCol}`}></div>
      <div className={styles.logoContainer}>
        <div className={`dpd-columns ${styles.logo}`}>
          <a href={`${domain}/index.jsp`}>
            <img
              src={local ? "assets/dpdLocal.png" : "assets/dpdLogo.png"}
              alt="dpdLogo"
            />
          </a>
        </div>

        <div className={`dpd-columns ${styles.claim}`}>
          <img
            src={local ? "assets/dpdLocalclaim.png" : "assets/claim.png"}
            className="right"
            alt="claim"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
