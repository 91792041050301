export const getTranslation = (flag) => {
  switch (flag) {
    case "GB":
      return require("../translations/locale-gb.json");
    case "NL":
      return require("../translations/locale-nl.json");
    case "FR":
      return require("../translations/locale-fr.json");
    case "DE":
      return require("../translations/locale-de.json");
    case "ES":
      return require("../translations/locale-es.json");
    case "PT":
      return require("../translations/locale-pt.json");
    case "EE":
      return require("../translations/locale-ee.json");
    case "LV":
      return require("../translations/locale-lv.json");
    case "LT":
      return require("../translations/locale-lt.json");
    case "AT":
      return require("../translations/locale-at.json");
    case "DK":
      return require("../translations/locale-dk.json");
    case "SE":
      return require("../translations/locale-se.json");
    case "FI":
      return require("../translations/locale-fi.json");
    case "CZ":
      return require("../translations/locale-cz.json");
    case "PL":
      return require("../translations/locale-pl.json");
    case "SK":
      return require("../translations/locale-sk.json");
    default:
      return require("../translations/locale-gb.json");
  }
};
