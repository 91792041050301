import Modal from "react-modal";

import styles from "./Modal.module.scss";

Modal.setAppElement("#modal");

const ModalItem = ({
  showModal,
  setShowModal,
  location,
  home,
  domain,
  distance,
  postcode,
  translation,
}) => {
  const { locality, town } = location.address;

  const renderTown = (town, locality) => {
    if (locality) {
      return (
        <>
          <br />
          {locality}
          <br />
        </>
      );
    } else if (town) {
      return (
        <>
          <br />
          {town}
          <br />
        </>
      );
    }
    return (
      <>
        <br />
      </>
    );
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => setShowModal(false)}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.container}>
        <img src="/assets/pickup.png" alt="pickup" className={styles.img} />
        <div className={`dpd-row ${styles.panel}`}>
          <div className={styles.map}>
            <img
              src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/url-https%3A%2F%2Fwww.dpd.co.uk%2Fang-component%2Fwebcomponents%2Fpickup%2Fimg%2Fmarkers%2Fdpd_pickup_map-pin_0.png(${location.addressPoint.longitude},${location.addressPoint.latitude})/${location.addressPoint.longitude},${location.addressPoint.latitude},15,0/300x200?access_token=${process.env.REACT_APP_MAPBOX_KEY}`}
              alt="marker"
            />
          </div>
          <div className={styles.details}>
            <div className={`dpd-columns`}>
              <h2 className={styles.title}>{location.name}</h2>
            </div>
            <div className={styles.row}>
              <div className={`dpd-columns ${styles.col1}`}>
                <div>
                  <div className={styles.org}>
                    {location.address.organisation}
                  </div>
                  {location.address.street}
                  {renderTown(town, locality)}
                  {location.address.postcode}
                </div>
                <div className={styles.directions}>
                  <img src="/assets/car.png" alt="car" />
                  <span>{distance.toString().substring(0, 3)} Miles</span>
                </div>
                <a
                  href={`https://www.google.com/maps/dir/${postcode}/${location.addressPoint.latitude},${location.addressPoint.longitude}`}
                  className={styles.getDirections}
                  target="_blank"
                  rel="noreferrer"
                >
                  {translation.DIRECTIONS} &gt;
                </a>
              </div>
              <div className={`dpd-columns ${styles.col2}`}>
                <div className={styles.locationOpening}>
                  <div className="dpd-columns">
                    <table>
                      <thead>
                        {location.pickupLocationAvailability
                          .pickupLocationOpenWindow[0] && (
                          <tr>
                            <td>{translation["NORMAL_OPENING_TIMES"]}</td>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {location.pickupLocationAvailability
                          .pickupLocationOpenWindow[0] && (
                          <tr>
                            <td>{translation["Mon - Fri"]}:</td>
                            <td>{`${location.pickupLocationAvailability.pickupLocationOpenWindow[0]?.pickupLocationOpenWindowStartTime} - ${location.pickupLocationAvailability.pickupLocationOpenWindow[1]?.pickupLocationOpenWindowEndTime}`}</td>
                          </tr>
                        )}
                        {location.pickupLocationAvailability
                          .pickupLocationOpenWindow[5] && (
                          <tr>
                            <td>{translation.Sat}:</td>
                            <td>{`${location.pickupLocationAvailability.pickupLocationOpenWindow[5]?.pickupLocationOpenWindowStartTime} - ${location.pickupLocationAvailability.pickupLocationOpenWindow[5]?.pickupLocationOpenWindowEndTime}`}</td>
                          </tr>
                        )}
                        {location.pickupLocationAvailability
                          .pickupLocationOpenWindow[6] && (
                          <tr>
                            <td>{translation.Sun}:</td>
                            <td>{`${location.pickupLocationAvailability.pickupLocationOpenWindow[6]?.pickupLocationOpenWindowStartTime} - ${location.pickupLocationAvailability.pickupLocationOpenWindow[6]?.pickupLocationOpenWindowEndTime}`}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`dpd-row ${styles.row}`}>
          <div className={`dpd-columns ${styles.locationIcons}`}>
            {location.dropOffDigital &&
              location.dropOffReturn &&
              location.dropOffDpd && (
                <div className={styles.iconCon}>
                  <img
                    className={styles.icons}
                    src={`assets/barcode-label-printing.svg`}
                    alt=""
                  />
                  <div>{translation["Barcode Label Printing"]}</div>
                </div>
              )}
            {location["disabledAccess"] && (
              <div className={styles.iconCon}>
                <img
                  className={styles.icons}
                  src={`assets/wheelchair.png`}
                  alt=""
                />
                <div>{translation.disabledAccess}</div>
              </div>
            )}
            {location["openLate"] && (
              <div className={styles.iconCon}>
                <img
                  className={styles.icons}
                  src={`assets/open-late.png`}
                  alt=""
                />
                <div>{translation["Open Late"]}</div>
              </div>
            )}
            {location["openSaturday"] && (
              <div className={styles.iconCon}>
                <img
                  className={styles.icons}
                  src={`assets/open-saturday.png`}
                  alt=""
                />
                <div>{translation["Open Saturdays"]}</div>
              </div>
            )}
            {location["openSunday"] && (
              <div className={styles.iconCon}>
                <img
                  className={styles.icons}
                  src={`assets/open-sunday.png`}
                  alt=""
                />
                <div>{translation["Open Sundays"]}</div>
              </div>
            )}
            {location["parkingAvailable"] && (
              <div className={styles.iconCon}>
                <img
                  className={styles.icons}
                  src={`assets/parking.png`}
                  alt=""
                />
                <div>{translation["Car Parking"]}</div>
              </div>
            )}
          </div>
          <div className={`dpd-columns ${styles.text}`}>
            <p>
              {translation["SHORT_VIDEO"].split("{")[0]}
              <a
                href={`${domain}/content/products_services/dpd_pickup.jsp`}
                target="_blank"
                rel="noreferrer"
              >
                {translation["HERE"].split(">")[1].split("<")[0]}
              </a>
            </p>
          </div>
        </div>
        <div className={styles.cross} onClick={() => setShowModal(false)}>
          x
        </div>
      </div>
    </Modal>
  );
};

export default ModalItem;
