import React from "react";
import styles from "./MobileNav.module.scss";

const MobileNav = ({
  showNav,
  setShowNav,
  businessTheme: { local, domain },
}) => {
  return (
    <>
      <div
        className={`${styles.mobileNav} ${
          showNav ? styles.moveLeft : styles.revert
        } dpd-row`}
      >
        <div className="large-12 dpd-columns">
          <nav className={`${styles.tabBar} show-for-small-only`}>
            <section className={styles.tabSection}>
              <h1>Pickup Point Finder</h1>
            </section>
            <section className={styles.tabSection2}>
              <a
                className={styles.menuIcon}
                href="#0"
                aria-expanded="false"
                onClick={() => setShowNav(!showNav)}
              >
                <span></span>
              </a>
            </section>
          </nav>
        </div>
      </div>
      <aside
        className={`${styles.sideNav} ${
          showNav ? styles.showNav : styles.hideNav
        }`}
      >
        <ul className={styles.offUl}>
          <li>
            <a
              title="Home"
              actionid="tY6Fre6C48kIGI8q"
              href={`${domain}/index.jsp`}
            >
              Home
            </a>
            <ul></ul>
          </li>
          <li>
            <a
              title="Products &amp; Services"
              actionid="Avylre6C48kIGOWD"
              href={`${domain}/content/products_services/index.jsp`}
            >
              Products &amp; Services
            </a>
            <ul></ul>
          </li>
          <li>
            <a
              title="Help"
              className={styles.active}
              actionid="0H_lre6C48kIGOX0"
              href={`${domain}/content/how-can-we-help/index.jsp`}
            >
              Help
            </a>
            <ul className={styles.ul2}>
              <li>
                <a
                  href={`${domain}/content/how-can-we-help/index.jsp`}
                  title="Track My Parcel"
                >
                  Track My Parcel
                </a>
              </li>
              <li>
                <a
                  href="/"
                  title="DPD Parcel Shop Finder"
                  className={styles.active}
                >
                  DPD Parcel Shop Finder
                </a>
              </li>
              <li>
                <a
                  href={
                    local
                      ? process.env.REACT_APP_DEPOT_LOCAL
                      : process.env.REACT_APP_DEPOT
                  }
                  title="Find My DPD Depot"
                >
                  Find My {local ? "Local" : "DPD"} Depot
                </a>
              </li>
              <li>
                <a
                  href={`${domain}/content/products_services/uk_issues.jsp`}
                  title="Service Disruption"
                >
                  Service Disruption
                </a>
              </li>
              {!local && (
                <li>
                  <a
                    href={`${domain}/apps/delivery_preferences/dpd-your-delivery-preferences.jsp`}
                    title="Your Delivery Preferences"
                  >
                    Your Delivery Preferences
                  </a>
                </li>
              )}
              {!local && (
                <li>
                  <a
                    href={`${domain}/pdf/dpd-complaints-charter.pdf`}
                    title="Complaints Charter"
                  >
                    Complaints Charter
                  </a>
                </li>
              )}
              {!local && (
                <li>
                  <a
                    href={`${domain}/content/products_services/packaging-tips.jsp`}
                    title="Packaging Tips"
                  >
                    Packaging Tips
                  </a>
                </li>
              )}
            </ul>
          </li>
          <li>
            <a
              title="MyDPD"
              actionid="4301re6C48kIGOZP"
              href={`${domain}/content/my_dpd/index.jsp`}
            >
              {!local ? "MyDPD" : "MyDPD Local"}
            </a>
            <ul></ul>
          </li>
          <ul></ul>
          {!local && (
            <li>
              <a title="Jobs" href={`https://jobs.dpd.co.uk/`}>
                Jobs
              </a>
              <ul></ul>
            </li>
          )}
          {local && (
            <li>
              <a
                title="About DPD Local"
                actionid="nyqTre6C48kIGOnf"
                href={`${domain}/content/about_dpd/index.jsp`}
              >
                About DPD Local
              </a>
              <ul></ul>
            </li>
          )}
          {local && (
            <li>
              <a title="Sustainability" href={process.env.REACT_APP_GREEN}>
                Sustainability
              </a>
              <ul></ul>
            </li>
          )}
          <li>
            <a
              title="Innovation"
              actionid="y_itre6C48kIGOao"
              href={process.env.REACT_APP_INNOVATION}
            >
              Innovation
            </a>
            <ul></ul>
          </li>
          {!local && (
            <li>
              <a title="Sustainability" href={process.env.REACT_APP_GREEN}>
                Sustainability
              </a>
              <ul></ul>
            </li>
          )}
          {!local && (
            <li>
              <a
                title="Purpose"
                actionid="VrE9re6C48kIGOgj"
                href={`${domain}/content/about_dpd/purpose.jsp`}
              >
                Purpose
              </a>
              <ul></ul>
            </li>
          )}
          <li className={styles.li}>
            <a
              className={styles.subAnchor}
              title="Sales Enquiry"
              actionid="cq19H.6C48kIGH4s"
              href={`${domain}/content/my_dpd/sales-enquiry.jsp`}
            >
              Sales Enquiry
            </a>
          </li>
          <ul></ul>
          <ul></ul>
          <ul></ul>
        </ul>
      </aside>
    </>
  );
};

export default MobileNav;
