import React, { useRef, useEffect, useCallback } from "react";
import styles from "./Map.module.scss";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const Map = ({ map, pickupData }) => {
  const currentMarkers = useRef([]);
  const mapContainer = useRef(null);

  const initialiseMap = useCallback(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-2.244644, 53.483959],
      zoom: 5,
    });

    map.current.addControl(new mapboxgl.FullscreenControl());
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
  }, [map]);

  useEffect(() => {
    initialiseMap();
  }, [initialiseMap]);

  useEffect(() => {
    if (Object.keys(pickupData).length !== 0) {
      if (currentMarkers.current.length > 0) {
        currentMarkers.current.forEach((marker) => {
          marker.remove();
        });
      }

      if (pickupData.results[0] !== undefined) {
        map.current.setCenter([
          pickupData.results[0].pickupLocation.addressPoint.longitude,
          pickupData.results[0].pickupLocation.addressPoint.latitude,
        ]);
        map.current.setZoom(12);

        pickupData.results.forEach((location, index) => {
          const el = document.createElement("div");
          el.className = "marker";
          el.style.backgroundImage = `url(assets/markers/dpd_pickup_map-pin_${
            index + 1
          }.fw.png)`;

          const marker = new mapboxgl.Marker(el)
            .setLngLat([
              location.pickupLocation.addressPoint.longitude,
              location.pickupLocation.addressPoint.latitude,
            ])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }).setHTML(
                `<strong class="popup-name">${location.pickupLocation.address.organisation}</strong>
                 <div>${location.pickupLocation.address.street}</div>
                 <div>${location.pickupLocation.address.town}</div>
                 <div>${location.pickupLocation.address.postcode}</div>
                `
              )
            )
            .addTo(map.current);

          currentMarkers.current.push(marker);
        });
      }
    }
  }, [pickupData, map]);

  return (
    <div>
      <div ref={mapContainer} className={styles.container} />
    </div>
  );
};

export default Map;
