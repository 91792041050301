import React, { useEffect, useState } from "react";

import styles from "./Footer.module.scss";

const Footer = ({ businessTheme: { domain, local } }) => {
  const [date, setDate] = useState("");

  useEffect(() => {
    setDate(new Date().getFullYear());
  }, []);

  return (
    <div className={`dpd-row ${styles.footer}`}>
      <hr className={styles.footerHr} />
      <div className={`${styles.footerCol1} dpd-columns`}>
        <p className={styles.copyright}>
          <img
            className={styles.copyrightImg}
            src="/assets/dpd_group_82x22.png"
            alt="DPD logo"
          />
          {` © ${date} DPD ${local ? "Local" : ""}`}
        </p>
      </div>
      <div className={`${styles.footerCol2} dpd-columns`}>
        <ul className={`${styles.footerUl} ${local ? styles.local : ""}`}>
          <li className={styles.footerLi}>
            <a
              title="Terms &amp; Conditions"
              actionid="L5TD7.6C48kIGILq"
              href={`${domain}/ts-and-cs.jsp`}
            >
              Terms &amp; Conditions
            </a>
          </li>
          <li className={styles.footerLi}>
            <a
              title="Privacy Policy"
              actionid="74zD7.6C48kIGIO1"
              href={`${domain}/privacy_policy.jsp`}
            >
              Privacy Policy
            </a>
          </li>
          <li className={styles.footerLi}>
            <a
              title="Phishing"
              actionid="4AzD7.6C48kIGIN7"
              href={`${domain}/content/about_dpd/phishing.jsp`}
            >
              Phishing
            </a>
          </li>
          <li className={styles.footerLi}>
            <a
              title="Data Protection &amp; GDPR"
              actionid="3hTD7.6C48kIGILG"
              href={`${domain}/gdpr.pdf`}
            >
              Data Protection &amp; GDPR
            </a>
          </li>
          <li className={styles.footerLi}>
            <a
              title="Sitemap"
              actionid="VPTD7.6C48kIGINp"
              href={`${domain}/sitemap.jsp`}
            >
              Sitemap
            </a>
          </li>
          <li className={styles.footerLi}>
            <a
              title="Fuel Surcharge"
              actionid="iNzr7.6C48kIGIec"
              href={`${domain}/content/my_dpd/fuel_surcharge.jsp`}
            >
              Fuel Surcharge
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
