import React, { useState, useEffect } from "react";
import styles from "./MobileToggle.module.scss";

const MobileToggle = ({
  setShowFilter,
  setShowMap,
  showMap,
  showFilter,
  translation,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    width <= 992 ? setShowMap(false) : setShowMap(true);
    if (width >= 922) {
      setShowFilter(true);
    }
  }, [width, setShowMap, setShowFilter]);
  return (
    <React.Fragment>
      {width <= 992 ? (
        <div className={styles.table}>
          <div
            className={`${styles.mobileBox} ${showMap ? styles.inactive : ""}`}
            onClick={() => {
              setShowFilter(!showFilter);
              setShowMap(false);
            }}
          >
            {translation["List View"]}
            <div
              className={`${styles.mobileArrow} ${
                showMap ? styles.mobileArrowUp : ""
              }`}
            >
              <div
                className={`${styles.overlay} ${
                  showMap ? styles.overlayUp : ""
                }`}
              ></div>
            </div>
          </div>
          <div
            className={`${styles.mobileBox} ${
              showFilter ? styles.inactive : ""
            }`}
            onClick={() => {
              setShowFilter(false);
              setShowMap(!showMap);
            }}
          >
            {translation["Map View"]}
            <div
              className={`${styles.mobileArrow} ${
                showFilter ? styles.mobileArrowUp : ""
              }`}
            >
              <div
                className={`${styles.overlay} ${
                  showFilter ? styles.overlayUp : ""
                }`}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default MobileToggle;
